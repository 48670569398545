/**

* MATERIAL UI OutlinedInput
 */

.MuiFormLabel-root.MuiInputLabel-outlined {
  background-color: white;
  padding: 0 5px;
  transform: translate(12px, 13px) scale(1);
}

.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 5px 14px;
  box-sizing: border-box;
  &.MuiOutlinedInput-inputSelect {
    padding-right: 30px;
    line-height: 33px;
  }
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
  display: flex;
  align-items: center;
  min-height: 43px;
}

.MuiInputBase-root.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-top: 0;
  padding-bottom: 0;
}

.MuiSelect-outlined.MuiSelect-outlined {
  min-height: 43px;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined {
  min-height: initial;
}
