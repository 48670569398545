/*
 * USEFULL FLEXBOX HELPERS
 */

.inline-flex {
  display: inline-flex;
}

.flex,
[class*='flex-'] {
  display: flex;
}

.flex {
  &-wrap {
    flex-wrap: wrap;
  }

  &-row {
    flex-direction: row;
  }
  &-column {
    flex-direction: column;
  }

  &-align-items-center {
    align-items: center;
  }
  &-align-items-start {
    align-items: flex-start;
  }
  &-align-items-end {
    align-items: flex-end;
  }

  &-justify-content-center {
    justify-content: center;
  }
  &-justify-content-end {
    justify-content: flex-end;
  }
  &-justify-content-space-between {
    justify-content: space-between;
  }
  &-justify-content-space-around {
    justify-content: space-around;
  }

  // flex shorthand helpers
  &-0 {
    flex: 0;
  }
  &-1 {
    flex: 1;
  }
}

.flex-completely-centered {
  align-items: center;
  justify-content: center;
}
