
.Landing {
  display: flex;
  min-height: 100vh;
  background: white url('../../assets/img/bg.jpg') center/cover no-repeat;
  justify-content: center;
  align-items: center;
  padding: 3em 2em;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: white;
}

.Wrapper {
  max-width: 1280px;
  width: 100%;
}

.Heading {
  font-size: 30px;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 50px;
}

.Body {
  font-weight: 300;
  max-width: 450px;
  line-height: 1.5;
  margin-bottom: 50px;
}

.Body-2 {
  font-weight: 500;
}

.Store {
  display: flex;
  margin: 40px 0;
}

.Store-icon {
  font-size: 0;
  line-height: 0;
  height: 48px;
}

.Store-icon.android {
  background: transparent url('../../assets/img/google_market.png') -11px center/auto 72px no-repeat;
  width: 166px;
}

.Store-icon.ios {
  background: transparent url('../../assets/img/apple_market.svg') center/145px auto no-repeat;
  margin-right: 24px;
  width: 145px;
}

.Url {
  font-style: italic;
  font-weight: 500;
  color: white;
  letter-spacing: .02em;
  text-decoration: none;
}
