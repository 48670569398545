
.MuiButton-contained.warn {
  color: white;
  background-color: #DE2C47;

  &:focus,
  &:hover {
    background-color: darken(#DE2C47, 5%);
  }
}
