/*
 * SPACING MIXIN
 * mixin for generate spacing patterns for margin and padding property
 */

// important boolean
$spacing-options-important: true !default;

// spacing variants and sizes
$spacing-options: (
  variants: (
    default: '',
    x: 'x',
    y: 'y',
    left: 'l',
    right: 'r',
    top: 't',
    bottom: 'b'
  ),
  sizes: (
    auto: 'auto',
    0: 0,
    1: 0.5
  )
) !default;

/*
 * $property: string - padding/margin
 * $variants-size-options: map -  size and variantes options
 * $unity: size multiplier by default "1rem"
 */

@mixin spacing($property, $variants-size-options, $unity: 1rem) {
  @each $variantKey,
    $variantKeyValue in map-get($variants-size-options, variants)
  {
    @each $sizeKey, $sizeKeyValue in map-get($variants-size-options, sizes) {
      $propertyFirstLetter: str-slice($property, 1, 1);

      // default variant
      @if $variantKey == 'default' {
        // css-selector
        .#{$propertyFirstLetter}-#{$sizeKey} {
          // css-declaration
          #{$property}: #{if(
              type-of($sizeKeyValue) == number,
              if($sizeKeyValue == 0, $sizeKeyValue, $sizeKeyValue * $unity),
              unquote($sizeKeyValue)
            )
            if($spacing-options-important, '!important', '')};
        }
      }

      // x axis variant
      @else if $variantKey == 'x' {
        // css-selector
        .#{$propertyFirstLetter}#{$variantKeyValue}-#{$sizeKey} {
          // css-declaration
          #{$property}-left: #{if(
              type-of($sizeKeyValue) == number,
              if($sizeKeyValue == 0, $sizeKeyValue, $sizeKeyValue * $unity),
              unquote($sizeKeyValue)
            )
            if($spacing-options-important, '!important', '')};
          // css-declaration
          #{$property}-right: #{if(
              type-of($sizeKeyValue) == number,
              if($sizeKeyValue == 0, $sizeKeyValue, $sizeKeyValue * $unity),
              unquote($sizeKeyValue)
            )
            if($spacing-options-important, '!important', '')};
        }
      }

      // y axis variant
      @else if $variantKey == 'y' {
        // css-selector
        .#{$propertyFirstLetter}#{$variantKeyValue}-#{$sizeKey} {
          // css-declaration
          #{$property}-top: #{if(
              type-of($sizeKeyValue) == number,
              if($sizeKeyValue == 0, $sizeKeyValue, $sizeKeyValue * $unity),
              unquote($sizeKeyValue)
            )
            if($spacing-options-important, '!important', '')};
          // css-declaration
          #{$property}-bottom: #{if(
              type-of($sizeKeyValue) == number,
              if($sizeKeyValue == 0, $sizeKeyValue, $sizeKeyValue * $unity),
              unquote($sizeKeyValue)
            )
            if($spacing-options-important, '!important', '')};
        }
      }

      // individual variants
      @else {
        // css-selector
        .#{$propertyFirstLetter}#{$variantKeyValue}-#{$sizeKey} {
          // css-declaration
          #{$property}-#{$variantKey}: #{if(
              type-of($sizeKeyValue) == number,
              if($sizeKeyValue == 0, $sizeKeyValue, $sizeKeyValue * $unity),
              unquote($sizeKeyValue)
            )
            if($spacing-options-important, '!important', '')};
        }
      }
    }
  }
}
