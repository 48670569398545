/*
 * TEXT UTILITIES
 */

// text align
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

// another helpers
.break-word {
  word-break: break-word;
}

// font-weights
.fw-light {
  font-weight: 300;
}

.fw-regular {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

// truncate text helper
.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

// text underline
.text-hover-underline {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

// list style
.list-style-none {
  list-style: none;
}

.text-gray-dark {
  color: $color-gray-dark !important;
}

// font-sizes
.fs-14px {
  font-size: 14px !important;
}

.fs-10px {
  font-size: 10px;
}
.fs-12px {
  font-size: 12px !important;
}

.text-white {
  color: #fff;
} 