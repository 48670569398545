/*
 * GLOBAL COLOR SETTINGS
 * color setting for all components
 */

// imported functions
@import '../tools/all';

/* brand colors
 ******************************************************************************/
$color-brand-primary: #142954;
$color-brand-accent: #ffcd02;
$color-brand-danger: #e40000;
$color-brand-success: #30b274;

/* gray scale
 ******************************************************************************/
$color-white: #fff;
$color-black: #000;

$color-gray-light: #eeeeee;
$color-gray: #bdbdbd;
$color-gray-dark: #616161;
