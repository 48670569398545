/*
 * Input type number
 * hide arrows for input type number
 */

input.no-buttons::-webkit-inner-spin-button,
input.no-buttons::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

input.no-buttons,
input.no-buttons:hover,
input.no-buttons:focus {
  -moz-appearance: textfield;
}
