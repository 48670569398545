/*
 * DISPLAY UTILITIES
 */

.display-none {
  display: none !important;
}

.display-block {
  display: block !important;
}

.display-inline-block {
  display: inline-block;
}
