.Auth {
  margin: auto;
  max-width: 400px;
  padding: 2em;

  &-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-progress {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}
