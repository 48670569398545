/*
 * CURSOR UTILITIES
 */

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-text {
  cursor: text !important;
}

.cursor-move {
  cursor: move !important;
}
