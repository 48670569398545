/*
 * HR TAG
 * base styles for hr tag
 */

hr {
  border: none;
  border-top: 1px solid $color-gray-light;
  margin: 0;
}
