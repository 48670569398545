/*
 * ROOT ELEMENTS (body, html)
 */

html {
  box-sizing: border-box;
  height: 100%;
}

body {
  box-sizing: border-box;
  min-height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  color: $body-base-color;
  font: normal normal $body-base-font-weight #{$body-base-font-size}/#{$body-base-line-height}
    $body-sans-serif-font;

  background-color: $body-background-color;
  margin: 0;
}
