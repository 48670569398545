/*
 * HELPERS FOR POSITION CSS PROPERTY
 */

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-static {
  position: static !important;
}
