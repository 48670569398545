/*
 * SIZE UTILITIES
 */

.width-100 {
  width: 100% !important;
}

.width-100px {
  width: 100px !important;
}

.max-width-180px {
  max-width: 180px !important;
}

.max-width-450px {
  max-width: 450px;
}

.max-width-650px {
  max-width: 650px;
}

.min-width-100px {
  min-width: 100px;
}
