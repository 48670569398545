/*
 * GLOBAL BODY ELEMENT
 */

// body settings
$body-base-font-size: 16px;
$body-base-color: $color-black;
$body-base-font-weight: normal;
$body-base-line-height: 1.25;
$body-sans-serif-font: Roboto, 'Helvetica Neue', Helvetica, Roboto, sans-serif;
$body-background-color: #eeeeee;
$main-content-spacing: 30px;

$content-max-width: 1200px;
